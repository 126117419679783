import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import BackgroundImage from '../../components/images/BackgroundImage';
import QMCloudsTietokonePuhelinImg from '../../components/images/QMCloudsTietokonePuhelinImg';

export default () => {
  const { t } = useTranslation();
  return (
    <BackgroundImage className="hero">
      <Container>
        <Row>
          <Col lg={6}>
            <h1 className="pricing-hero">{t('PriceHeroTitle')}</h1>
            <p>{t('PriceHeroParagraph')}</p>
          </Col>
          <Col lg={6}>
            <QMCloudsTietokonePuhelinImg className="img-w-500" />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  );
};
