import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ContactRequest from '../../components/ContactRequest';
import QMCloudsLaptopLaatudokumenttiImg from '../../components/images/QMCloudsLaptopLaatudokumenttiImg';

export default () => {
  const { t } = useTranslation();
  const checkIcon = <i className="fas fa-check check-icon" />;
  const minusIcon = <i className="fa fa-minus minus-icon"></i>;

  return (
    <Container className="container-space pricing-options">
      <Row>
        <Col className="overflow">
          <table className="table table-sm">
            <thead>
              <tr className="table-title">
                <th className="feature-name">{t('PricePlans')}</th>
                <th>{t('PriceBasic')}</th>
                <th>{t('PriceSuper')}</th>
                <th>{t('PriceAllIn')}</th>
              </tr>
            </thead>
            <tbody className="table table-striped">
              <tr>
                <td className="feature-name">{t('Price')}</td>
                <td>99 €/kk</td>
                <td>199 €/kk</td>
                <td>{t('PriceAsk')}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">{t('PriceUsers')}</td>
                <td>{t('PriceUsers1')}</td>
                <td>{t('PriceUsers3')}</td>
                <td>{t('PriceUsers4')}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">{t('PriceExtraUsers')}</td>
                <td>{minusIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">{t('PriceQmcloudsSoftware')}</td>
                <td> {checkIcon}</td>
                <td> {checkIcon}</td>
                <td> {checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">{t('PriceDocumentBuildTool')}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">{t('PriceReporting')}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">{t('PriceRiskAssessment')}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">{t('PriceAutomaticRaport')}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">{t('PriceMobileApp')}</td>
                <td>{minusIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">{t('PriceUsersManagement')}</td>
                <td>{minusIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">
                  {t('PriceEditableQualityManual')}
                </td>
                <td>{minusIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">
                  {t('PriceEditableEnvironmentManual')}
                </td>
                <td>{minusIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>

            <tbody>
              <tr>
                <td className="feature-name">{t('PriceEditableDocuments')}</td>
                <td>{minusIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">
                  {t('PriceImplementationSoftware')}
                </td>
                <td>{minusIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">{t('PriceSertificate')}</td>
                <td>{minusIcon}</td>
                <td>{checkIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">
                  {t('PriceCompleteQualitySystem')}
                </td>
                <td>{minusIcon}</td>
                <td>{minusIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">
                  {t('PriceCompleteEnvironmentSystem')}
                </td>
                <td>{minusIcon}</td>
                <td>{minusIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td className="feature-name">{t('PriceSoftwareTraining')}</td>
                <td>{minusIcon}</td>
                <td>{minusIcon}</td>
                <td>{checkIcon}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col lg={6}>
          <p className="text-blue font-weight-bold">
            {t('PricingOptionsCusttomTitle')}
          </p>
          <ul>
            <li>{t('PricingOptionsTailored')}</li>
          </ul>
          <p className="text-blue font-weight-bold">
            {t('PricingOptionsOtherTitle')}
          </p>
          <ul>
            <li>{t('PricingOptionsOther')}</li>
          </ul>
          <div className="mt-5">
            <ContactRequest />
          </div>
        </Col>
        <Col lg={6} className="mt-5">
          <QMCloudsLaptopLaatudokumenttiImg />
        </Col>
      </Row>
    </Container>
  );
};
