import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import withI18next from '../components/withI18Next';
import QmcloudsOgImg from '../images/qmclouds-og-img.png';
import PriceHero from '../pages-partials/price/PriceHero';
import PricingOptions from '../pages-partials/price/PricingOptions';

const IndexPage = ({ pageContext: { locale }, t }: any) => (
  <Layout locale={locale}>
    <SEO
      title={t('PricingSeoTitle')}
      description={t('PricingSeoDescription')}
      pageImage={QmcloudsOgImg}
      pageUrl="https://qmclouds.com/pricing"
      lang={locale}
    />
    <PriceHero />
    <PricingOptions />
  </Layout>
);
export default withI18next({ ns: 'common' })(IndexPage);
